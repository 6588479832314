* {box-sizing: border-box;outline: none;}
body {margin: 0;padding: 0; background-color: #fdf3f1; font-family: 'Open Sans', sans-serif;}
.container {max-width: 980px;width: 100%;margin: 0 auto;padding: 40px; background-color: white;}


/*-----------------------FLEX------------------------*/
.flex {display: -webkit-flex; display: -moz-flex; display: -ms-flex; display: -o-flex; display: flex;}
.com_box_title
{justify-content: space-between;}

.advanced_wrap
{justify-content: space-around; height: auto;}

.callback__con
{justify-content: flex-end;}

.nav-links
{justify-content: center;}

.header__phone
{justify-content: flex-start;}

.header__img__wrapper
{justify-content: flex-end;}

.body
{flex-direction: column;}

.body
{-ms-align-items: center; align-items: center;}

.car__pref__list__item1
{-ms-align-items: flex-end; align-items: flex-end;}

.change_wrap
{flex-wrap: wrap;}

/*-------------------header------------------*/

.svgElement {display: none;}
.main {padding: 40px 0;}
.td_custom_qr {
  flex-shrink: 0;
  width: 250px;
  height: 250px;
  margin-right: 40px;
}
.td_optins {
  width: 100%;
}
.url_wrap input[type="text"], .url_wrap input[type="password"], .url_wrap textarea {
  width: 100%;
  height: 35px;
  border: 2px solid #61ce70;
  padding-left: 5px; 
  margin-bottom: 10px;
}
.url_wrap textarea {
  padding-top: 6px;
  max-width: 100%;
  min-height: 100px;
  resize: vertical;
}
.url_wrap span {
  display: block;
  margin-bottom: 10px;
}
.wrap_group {
  margin-top: 20px;
  padding: 0;
  flex-direction: column;
}
.title {
  display: block;
  margin-top: 10px;
  margin-bottom: -10px;
}
.wrap_group .item {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: 0px 1px 2px 0px #0000004f;
}
.wrap_group .td_custom_qr {
  width: 120px;
  height: auto;
}
.qr_active_logo {
  display: inline-block;
  margin: 15px 20px 0 0;
  height: 30px;
  padding-top: 4px;
  text-align: center;
  cursor: pointer;
  background-color: #dccccc;
  padding: 5px;
  width: 210px;
  line-height: 22px;
  transition: .3s;
  text-transform: uppercase;
}
.qr_active_logo:hover {background-color: #c7ac9e;}
.qr_bgcolor_label {
  margin-top: 10px;
  margin-left: 0;
  margin-right: 0;
  padding: 4px;
  width: 100%;
  border-radius: 5px;
}
.range_wrap {
  width: 100%;
  text-align: center;
}
.qr_bgcolor {width:50px}
.svgElement {display: none;}

/*-------------------------------------------- */

.tabs { width: 100%; }
.tabs__list {
  width: 100%;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
}
.tabs__list-item {
  padding: 5px 20px;
  display: inline-block;
  cursor: pointer;
  transition: .3s;
}
.tabs__list-item.active-tab {
  box-shadow: 0 0 0 1px #4a3933;
}
.tabs__list-item:hover {
  background-color: #c7ac9e;
}

.tabs__content {
  display: block;
}

input[type="radio"] {
  height: 20px;
  width: 20px;
  margin: 0 40px 0 10px;
}

.letter-holder {
  font-size: 28px;
  color: #e42320;
  margin: 8px 0 22px;
  line-height: 0;
}
.letter-holder { justify-content: center; }
.letter-holder .letter {
    animation-name: loadingF;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-direction: linear;
}

.l-1 {animation-delay: .48s;}
.l-2 {animation-delay: .6s;}
.l-3 {animation-delay: .72s;}
.l-4 {animation-delay: .84s;}
.l-5 {animation-delay: .96s;}
.l-6 {animation-delay: 1.08s;}
.l-7 {animation-delay: 1.2s;}
@keyframes loadingF {
    0% {opacity: 0;}
    100% {opacity: 1;}
}


/*-------------------------------------------- */
@media only screen and (max-width: 620px) {
  .container:not(.wrap_group) {
    padding: 15px;
  }
  
  .tabs__content > .container {
    flex-direction: column-reverse;
    padding: 10px 0;
  }
  .td_optins {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 426px) {
  .td_custom_qr {
    margin-right: 20px;
  }
  body {
    font-size: 14px;
  }
}
@media only screen and (max-width: 321px) {
  .td_custom_qr {
    margin-right: 10px;
  }
  body {
    font-size: 12px;
  }
  .tabs__list-item { padding: 5px 10px; }
  .wrap_group .td_custom_qr {
    width: 90px;
  }
}